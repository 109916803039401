import { type AfterViewInit, ChangeDetectorRef, Component, type OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, fromEvent } from 'rxjs';
import { map, take } from 'rxjs/operators';

import type {
    BlockStructureEntity,
    ColumnStructureEntity,
    SectionStructureEntity,
    SpecificBlockStructureEntity,
    StructurePageStructureEntity,
    WebWidgetEntity,
} from '@web/types';

import { ELanguages, Widgets } from '@common/enums';
import { PlatformService } from '@common/services/platform.service';
import { ServerStylesService } from '@common/services/server-styles.service';

import { WebsiteThemeStrategy, WebThemeFormatter } from '@libs/themes';

import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { LinksService } from '@web-builder/core/services/links.service';
import { UtilsService } from '@web-builder/core/services/utils.service';

import { commonMinSiteId } from '@common/constants/min-site-id.consts';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { type Widget } from '@common/types/content.type';
import { UseAnalytics } from '@sites/analytics/hooks';
import { ProductCatalogService } from '@web-builder/core/services/product-catalog.service';

@Component({
    selector: 'web-builder-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements AfterViewInit, OnInit {
    public readonly structure: (BlockStructureEntity | SpecificBlockStructureEntity)[] = this.transferStateService.get('structure');
    private readonly themeFormatter: WebThemeFormatter = new WebThemeFormatter();
    private readonly websiteThemeStrategy: WebsiteThemeStrategy = new WebsiteThemeStrategy();

    private readonly MAX_EQUAL_COLUMN_COUNT: number = 4;

    public readonly minSiteId: number = commonMinSiteId;

    public readonly Widgets = Widgets;

    public isMobile: boolean = false;

    public preparedStructure: (BlockStructureEntity | SpecificBlockStructureEntity)[] = [];

    constructor(
        private readonly stylesService: ServerStylesService,
        private readonly platformService: PlatformService,
        private readonly transferStateService: TransferStateService,
        private readonly linkService: LinksService,
        private readonly utilsService: UtilsService,
        private readonly useAnalytics: UseAnalytics,
        private readonly router: Router,
        private readonly productCatalogService: ProductCatalogService,
        public readonly changeDetectorRef: ChangeDetectorRef,
    ) {
        if (this.platformService.isPlatformServer()) {
            const themeStyles = {
                '@global': {
                    ':root': this.getFormattedThemeSettings(),
                },
            };
            this.stylesService.getStyles('theme', themeStyles);

            if (this.transferStateService.isPreview) {
                const themeSettings = this.transferStateService.get('themeSettings');
                this.linkService.setFontsLinks(themeSettings.fonts);
            }
        }

        if (this.platformService.isPlatformBrowser() && !this.transferStateService.isPreview) {
            this.linkService.setThemeSettingsLink();

            this.useAnalytics.trackPageVisit(this.transferStateService.get('pageId'));
        }
    }

    public ngAfterViewInit(): void {
        if (this.platformService.isPlatformServer()) {
            return;
        }

        const anchorWithSearch = document.location.hash.split('#')[1];

        if (!anchorWithSearch) {
            return;
        }

        const anchor = anchorWithSearch.split('?')[0];

        if (anchor) {
            const el = document.getElementById(decodeURI(anchor));

            if (!el) {
                return;
            }

            window.onload = () => el.scrollIntoView({ behavior: 'smooth' });
        }
    }

    public ngOnInit() {
        gsap.registerPlugin(ScrollTrigger);

        if (this.platformService.isPlatformBrowser()) {
            this.isMobile = window.matchMedia('(max-width: 992px)').matches;
            fromEvent(window, 'resize')
                .pipe(
                    debounceTime(500),
                    map(() => {
                        return window.matchMedia('(max-width: 992px)').matches;
                    }),
                )
                .subscribe((isMobile) => {
                    this.isMobile = isMobile;
                });
        }
        this.updateWidgets();
    }

    public getBlockDisplayId(block: BlockStructureEntity): string {
        return block?.displayId || block.id.substring(0, 8);
    }

    public getColumnClassesSpec(column: ColumnStructureEntity, columnCount: number): string {
        if (columnCount > this.MAX_EQUAL_COLUMN_COUNT) {
            return `lpc-col-equal-${columnCount}`;
        }

        const columnWidth = column.data?.columnWidth || 12 / columnCount;
        return `lpc-col-${columnWidth} lpc-col-equal-${columnCount}`;
    }

    public getColumnClasses(column: ColumnStructureEntity, section: SectionStructureEntity): string {
        if (section.columns.length > this.MAX_EQUAL_COLUMN_COUNT) {
            return `lpc-col-equal-${section.columns.length}`;
        }

        const columnWidth = column.data?.columnWidth || 12 / section.columns.length;

        if (section.columnWidthFlex) {
            return `lpc-col-equal-${section.columns.length}`;
        } else {
            return `lpc-col-${columnWidth} lpc-col-equal-${section.columns.length}`;
        }
    }

    public getColumnWidth(column: ColumnStructureEntity, section: SectionStructureEntity): string {
        if (!section.columnWidthFlex) {
            return;
        }

        const columnWidth = column.data?.columnWidthPer || 100 / section.columns.length;
        return columnWidth + '%';
    }

    private getFormattedThemeSettings(): Record<string, string> {
        const themeSettings = this.transferStateService.get('themeSettings');
        try {
            return this.themeFormatter.formatTheme(themeSettings);
        } catch (error) {
            const colors = this.websiteThemeStrategy.colorPresets;
            const buttons = this.websiteThemeStrategy.buttonPresets;
            const fonts = this.websiteThemeStrategy.fontPresets;

            return this.themeFormatter.formatThemeButtons({
                colors: {
                    selected: colors[0].values,
                    selectedIndex: colors[0].index,
                },
                buttons: {
                    selected: buttons[0].values,
                    selectedIndex: buttons[0].index,
                },
                fonts: {
                    selected: fonts[0].values,
                    selectedIndex: fonts[0].index,
                },
            });
        }
    }

    public is404Page(): boolean {
        const site = this.transferStateService.get('site');
        const pageId = this.transferStateService.get('pageId');
        return Number(site?.settings?.not_found_page_id) === Number(pageId);
    }

    public isSpecificBlockHidden(block: BlockStructureEntity) {
        const pageId = this.transferStateService.get('pageId');
        return block?.sharedBlockProps?.hideOnPage?.[Number(pageId)];
    }

    public isSharedBlock(block: BlockStructureEntity): boolean {
        return !!block.isShared && block.type === 'DEFAULT';
    }

    public get showPoweredBy(): boolean {
        return this.transferStateService.get('showPoweredBy');
    }

    public get siteId(): number {
        return this.transferStateService.get('siteId');
    }

    public get isTestGroupSiteId(): boolean {
        return Boolean(this.siteId > this.minSiteId);
    }

    public get isOddSiteId(): boolean {
        return Boolean(this.siteId % 2 !== 0);
    }

    public get sendpulseLogoImg(): string {
        return this.utilsService.formatImgSrc('./assets/img/websites/sendpulse-logo-symbol.svg');
    }

    public get sendpulseArticleLink(): string {
        const lang = this.transferStateService.get('lang') as ELanguages;
        const urmTags = '?utm_source=sendpulse&utm_medium=poweredby&utm_campaign=website-page';
        const articles = {
            [ELanguages.EN]: 'https://sendpulse.com/features/landing-page-builder',
            [ELanguages.RU]: 'https://sendpulse.com/ru/features/landing-page-builder',
            [ELanguages.UA]: 'https://sendpulse.ua/features/landing-page-builder',
            [ELanguages.FR]: 'https://sendpulse.com/fr/features/landing-page-builder',
            [ELanguages.PT_BR]: 'https://sendpulse.com/br/features/landing-page-builder',
            [ELanguages.ES_MX]: 'https://sendpulse.com/latam/features/landing-page-builder',
            [ELanguages.TR]: 'https://sendpulse.com/tr/features/landing-page-builder',
        };
        return `${articles[lang] || articles[ELanguages.EN]}${urmTags}`;
    }

    public get sendpulseArticleLinkTestV1(): string {
        const lang = this.transferStateService.get('lang') as ELanguages;
        const urmTags = '?utm_source=sendpulse&utm_medium=poweredby&utm_campaign=website-page-v1';
        const articles = {
            [ELanguages.EN]: 'https://sendpulse.com/features/landing-page-builder',
            [ELanguages.RU]: 'https://sendpulse.com/ru/features/landing-page-builder',
            [ELanguages.UA]: 'https://sendpulse.ua/features/landing-page-builder',
            [ELanguages.FR]: 'https://sendpulse.com/fr/features/landing-page-builder',
            [ELanguages.PT_BR]: 'https://sendpulse.com/br/features/landing-page-builder',
            [ELanguages.ES_MX]: 'https://sendpulse.com/latam/features/landing-page-builder',
            [ELanguages.TR]: 'https://sendpulse.com/tr/features/landing-page-builder',
        };
        return `${articles[lang] || articles[ELanguages.EN]}${urmTags}`;
    }

    public get sendpulseArticleLinkTestV3(): string {
        const lang = this.transferStateService.get('lang') as ELanguages;
        const urmTags = '?utm_source=sendpulse&utm_medium=poweredby&utm_campaign=website-page-v3';
        const articles = {
            [ELanguages.EN]: 'https://sendpulse.com/features/landing-page-builder',
            [ELanguages.RU]: 'https://sendpulse.com/ru/features/landing-page-builder',
            [ELanguages.UA]: 'https://sendpulse.ua/features/landing-page-builder',
            [ELanguages.FR]: 'https://sendpulse.com/fr/features/landing-page-builder',
            [ELanguages.PT_BR]: 'https://sendpulse.com/br/features/landing-page-builder',
            [ELanguages.ES_MX]: 'https://sendpulse.com/latam/features/landing-page-builder',
            [ELanguages.TR]: 'https://sendpulse.com/tr/features/landing-page-builder',
        };
        return `${articles[lang] || articles[ELanguages.EN]}${urmTags}`;
    }

    public getSpecificWidgets(block: SpecificBlockStructureEntity) {
        return block.sections
            .flatMap((section) => section.columns)
            .flatMap((column) => column.widgets)
            .filter((widget) => !block.data.hideWidgetsOnMobile[widget.id] && widget.name !== Widgets.LOGO);
    }

    public getSpecificBlockLogoWidget(block: SpecificBlockStructureEntity) {
        const widget = block.sections
            .flatMap((item) => item.columns)
            .flatMap((item) => item.widgets)
            .find((item) => item.name === Widgets.LOGO);

        if (block.data.hideWidgetsOnMobile[widget.id]) {
            return null;
        }

        return widget;
    }

    public getWidgetZIndex(widget: Widget): number {
        switch (widget.name) {
            case Widgets.MENU:
                return 3;
            case Widgets.VIDEO:
                return 3;
            case Widgets.FORM:
                return 2;
            default:
                return 1;
        }
    }

    public isStructure(widget: WebWidgetEntity | StructurePageStructureEntity): boolean {
        return 'widgets' in widget;
    }

    // @HostListener('click', ['$event'])
    // public linkClickEvent(event: MouseEvent): void {
    //     const target = event.target;
    //
    //     if (target instanceof HTMLElement) {
    //         if (target.tagName === 'A') {
    //             const urlTarget = target.getAttribute('target');
    //             const urlHref = target.getAttribute('href');
    //             const currentUrl = window.location.href;
    //             const params = new URL(currentUrl).search;
    //
    //             if (this.isSameSite(urlHref) && params) {
    //                 event.preventDefault();
    //                 window.open(`${urlHref}${params}`, urlTarget);
    //             }
    //         }
    //     }
    // }

    private isSameSite(targetUrl: string): boolean {
        return targetUrl?.includes(window.location.hostname);
    }

    public updateWidgets(): void {
        this.preparedStructure = this.structure;
        const flatWidgets: WebWidgetEntity[] = this.flattenWidgets();

        if (!flatWidgets.length) {
            return;
        }

        this.productCatalogService
            .adaptCatalogWidgetsData(flatWidgets)
            .pipe(
                take(1),
                map((updatedWidgets) => {
                    this.updateStructureWithWidgets(updatedWidgets);
                }),
            )
            .subscribe();
    }

    private flattenWidgets(): WebWidgetEntity[] {
        const flatWidgets: WebWidgetEntity[] = [];

        this.structure.forEach((block) => {
            block.sections?.forEach((section) => {
                section.columns?.forEach((column) => {
                    column.widgets?.forEach((widget) => {
                        flatWidgets.push(widget);
                    });
                });
            });
        });

        return flatWidgets;
    }

    private updateStructureWithWidgets(updatedWidgets: WebWidgetEntity[]): void {
        const widgetMap = new Map(updatedWidgets.map((widget) => [widget.id, widget]));

        this.preparedStructure = this.preparedStructure.map((block) => {
            return {
                ...block,
                sections: block.sections?.map((section) => ({
                    ...section,
                    columns: section.columns?.map((column) => ({
                        ...column,
                        widgets: column.widgets?.map((widget) => widgetMap.get(widget.id) || widget),
                    })),
                })),
            };
        });

        this.changeDetectorRef.detectChanges();
    }
}
